html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td,
img {
  margin: 0;
  padding: 0;
}
img {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}

html,
body {
  height: 100%;
  background-color: #f6f0e7;
  background-image: url("../../public/images/Texture-01.jpg");
}

#page {
  position: relative;
  width: 72.5%;
  /* min-height: 100%; */
  background-color: #f6f0e7;
  margin: 0 auto;
  text-align: center;
}

#title {
  padding: 10px;
  text-align: center;
}

body {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1em;
  color: #341d02;
  text-align: left;
}
/*
h1 {
  height: 240px;
  min-height: 180px;
  background-image: url(/layout/logo.png);
  background-size: 400px 180px;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -9999px;
} */

h2 {
  font-size: 2.8em;
  margin-top: 0;
  margin-bottom: 1em;
  text-align: center;
}

h3 {
  font-size: 2.5em;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  text-align: center;
}

p {
  font-size: 2em;
  margin-bottom: 1em;
  line-height: 1.25em;
}

a {
  color: #742c20;
  text-decoration: none;
}
a:link {
  color: #742c20;
}
a:visited {
  color: #742c20;
}
a:hover {
  color: #742c20;
  text-decoration: underline;
}

a.link {
  color: #742c20;
}

#menu {
  width: 100%;
  font-size: 2em;
  padding: 7px 0 7px 0;
  background-color: #742c20;
  text-align: center;
}
#menu a {
  padding: 10px;
}

#email {
  text-align: center;
}

#menu a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  text-transform: uppercase;
}

#menu a {
  color: #bda7a7;
}
#menu a:link {
  color: #bda7a7;
}
#menu a:visited {
  color: #bda7a7;
}
#menu a:hover {
  color: #fff;
}

#menu a.selected {
  color: #fff;
}

div.col-full {
  clear: both;
  margin: 10px 10px 20px 10px;
}

div.col-half {
  /* width: 460px;
  min-height: 460px; */
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: left;
}

div.col-wide {
  /* width: 620px; */
  float: left;
  margin: 10px 10px 20px 10px;
  text-align: left;
}
div.col-narrow {
  /* width: 300px; */
  float: left;
  margin: 10px 10px 20px 10px;
  padding-top: 50px;
  text-align: left;
}

div.col-text {
  /* width: 660px; */
  float: left;
  margin: 10px 160px 20px 160px;
  text-align: left;
}

div.col-narrow p {
  font-size: 0.9em;
}
div.col-narrow p:first-line {
  font-weight: bold;
  line-height: 2em;
}

.slideshow-captions {
  margin: 10px 0 10px 0;
}
.slideshow-thumbnails {
  margin: 10px 0 10px 0;
}

.slideshow-images img {
  border: 1px solid #341d02;
}

.slideshow-captions {
  font-size: 0.9em;
  color: #341d02;
  text-align: left;
}

li.featured {
  width: 300px;
  float: left;
  text-align: left;
  /* align: left; */
  margin: 10px 10px 10px 10px;
}

ol {
  clear: both;
}

#footer {
  clear: both;
  margin: 0 0 0 0;
  padding: 20px 0 20px 0;
  border-top: 1px solid #742c20;
  font-size: 1.5em;
}

form {
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: block;
  font-size: 1.8em;
  font-weight: lighter;
  margin: 1em 0 0 0;
}

input.name,
input.email,
input.location {
  width: 80%;
  font-size: 1.4em;
  color: #742c20;
  background-color: #fff;
  border: 1px solid #aaa;
  padding: 0.2em 0.2em;
}

input.button {
  display: block;
  font-size: 1.5em;
  color: #fff;
  background-color: #742c20;
  border: 0px solid #fff;
  padding: 0.3em 0.6em;
  margin: 20px 1em 20px 0;
  cursor: pointer;
  cursor: hand;
}

.page-row {
  margin-top: 2%;
  margin-bottom: 2%;
  /* margin-left: 0.5%;
  margin-right: 0.5%; */
}

@media (max-width: 992px) {
  .page-row {
    margin-top: 5%;
    /* margin-bottom: 2%;
    margin-left: 1%;
    margin-right: 1%; */
  }
}

/* @media (min-width: 1200px) {
  .page-row {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media (min-width: 992px) {
  .page-row {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media (min-width: 768px) {
  .page-row {
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media (min-width: 660px) {
  .page-row {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 1%;
    margin-right: 1%;
  }
} */

.page-image {
  max-width: 120%;
  height: auto;
}

@media (max-width: 659px) {
  #page {
    width: 90%;
  }
}

@media (max-width: 768px) {
  #page {
    width: 90%;
  }
}

@media (max-width: 448px) {
  #page {
    width: 90%;
  }
  p {
    font-size: 1.5em;
    margin-bottom: 1em;
    line-height: 1.25em;
  }
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  /* height: 100px;
  width: 100px; */
  outline: black;
  background-color: rgba(0, 0, 0, 0.3);
  /* background-size: 100%, 100%;
  border-radius: 50%; */
  /* border: 1px solid black; */
}

.fix-top {
  position: sticky;
  padding-top: 2px;
  background-color: #f6f0e7;;
  top: 0;
  z-index: 1;
}

.drop {
  margin-left: 5px;
  padding-top: 5px;
  /* opacity: 0.8; */
}

.more {
  color: #742c20;
  background-color: #f6f0e7;;
  opacity: 1;
}
